.excelHeader {
  background: var(--primaryColor);
  color:#fff ;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

select.excelDropDown {
  width: 100%;
  margin: auto;
  display: block;
  height: 100%;
  padding-left: 10px;
  font-size: 16px;
}

.excelChkWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.excelChkWrapper > label {
  position: relative;
  top: -3px;
}

.bgResponsive {
  width: 100%;
  height: 45px;
  background-size: contain;
  padding: 5px;
  background-repeat: no-repeat;
}
.excelPhoto {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 4px;
}

.excelDate {
  padding-top: 4px;
}

.exportOptions {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.exportOptions > button {
  width: 35%;
}

.excelGrid {
  width: 98%;
  margin-bottom: 20px;
}

.excelGrid span.rdg-header-sort-cell {
  width: 100%;
}

.excelAddBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 70%;
}

.excelChkWrapper > label {
  margin-right: 0;
}

.excelGrid .filterColumn {
  border: none;
  outline: none;
  box-shadow: none;
  min-height: 1.3rem;
  position: relative;
  top: 7px;
}

.sortingIcon {
  color: #ccc;
}
.sortingIcon.active {
  color: #fff;
}

.hideUnhideCols > div {
  margin-right: 10px;
}
.flexColumn > label {
  font-size: 0.8rem;
}

.excelGrid .excelGrid {
  width: 100%;
}

.excelGrid .rdg-cell {
  overflow: visible !important;
  text-overflow: inherit;
}
.excelGrid .rdg-cell .uiAutoTxt {
  position: relative;
  top: 10px;
  width: 94%;
  left: 8px;
}

.flexColumn select {
  min-height: 25px;
  position: relative;
  top: 8px;
}

.sortWrapper {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -9;
}

.excelGrid .selectHeaderCell {
  min-height: 50px;
}

.exportOptions{
  width:50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}