.uiLeftDrawerBox {
    width: 300px;
}
.hChart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
div#chartForm {
    width: 35%;
    margin-top: 10px;
}
.hChart div[data-highcharts-chart] {
    border: 1px solid #ccc;
    margin-top: -10px;
}
.highchartBox {
    margin-bottom: 30px;
}
div#projectOverview {
    width: 80%;
    display: flex;
    flex-direction: column;
}
.reportTable {
    width: 100%;
    overflow-x: auto;
}
.reportTable table{
    font-size: 14px;
    margin-bottom: 30px;
}
.reportTable table th {
    font-weight: 500;
    background: var(--secondaryColor);
    color: #fff;
}

.dashboardMenuWrap{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px !important;
}
.dashboardMenu {
    border: 1px solid #d1d1d1 !important;
    width: 20%;
    border-radius: 4px !IMPORTANT;
    box-shadow: 1px 2px 5px #ccc;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between !important;
    margin-right: 45px !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
}
.dashboardMenu>h4 {
    white-space: break-spaces;
    text-align: center;
    font-size: 1vw;
}

.dashboardMenu>img {
    width: 35%;
    padding: 10px;
    padding-left: 0px;
}