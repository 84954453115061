:root {
  --primaryColor: #0a6cc9;
  --secondaryColor: #06283D;
  --dimGray:#e1e1e1;
  --subTitleGray:#7c7c7c;
}
  
html,body,#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  position: relative;
  font-family: 'Roboto',sans-serif;
}

div#root {
  overflow-x: hidden;
  overflow-y: auto;
}

table.dx-datagrid-table tbody td {
  border-bottom: 1px solid #ccc;
}
.WrappedColumnClass {
  white-space: normal;
  word-wrap: break-word;
}

.formGroupName{
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 5px;
}

.viewBtn {
  background: #1363dfba;
  position: absolute;
  bottom: 5px;
  width: 80px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  padding: 5px 0px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.drive{
  border: 1px solid #ccc;
  width: 98%;
}
.folders {
  width: 100%;
  position: relative;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 20px;
  padding-top: 5px;
}
.folder {
  width: 130px;
    height: 100px;
    border: 1px solid #0a6cc930;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 20px;
    background-color: #0a6cc90d;
}
.folder h4 {
  margin-top: 4px;
  margin-bottom: 0px;
  text-transform: capitalize;
  font-size: 14px;
}
.folder>svg {
  font-size: 60px;
  color: var(--primaryColor);
}

button.reportBtn {
  background: #fff;
  box-shadow: none;
  border: none;
  border-radius: 3px;
  padding: 6px 0px;
  font-size: 18px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  color: #0000008a;
  text-transform: uppercase;
  cursor: pointer;
  padding-left: 3px;
}

li.leftMenu.gantt svg {
  transform: rotate(90deg);
}

.uiAutoTxt .MuiChip-root {
  background: #ddd;
  border-radius: 5px;
  position: relative;
  top: -4px;
  left: -3px;
  padding: 0px !important;
}

.leftBar>ul>li>div h4 {
  color: #fff;
  opacity: 0;
  transition: all 0.5s;
}
.leftBar.active>ul>li>div h4 {
  opacity: 1;
}
.leftBar>ul>li:not(:first-child)>div>div:first-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div[role="tooltip"] {
  z-index: 99999;
}

/* Data Grids */
.MuiDataGrid-columnHeader {
  background: var(--primaryColor);
  color: #fff;
  text-transform: uppercase;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}
.dx-header-row {
  background: var(--primaryColor);
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
} 

.formGroup button[disabled]>div {
  top: 7px;
  left: 14px;
}

button.uiBtn {
  font-size: 12px !important;
  min-width: 100px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.shadowTxt{
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  padding-top: 30px;
  color: #fff;
  text-shadow: 1px 2px 4px #000;
}

.fontXL{
  font-size: 60px;
}
.fontL{
  font-size: 30px;
}
.fontM{
  font-size: 30px;
}

.red {
  color: red;
}
.green {
  color: #00b100;
}
.dim {
  color: #ccc;
}

.width100 {
  width: 100%;
}

.bgBanner{
  background-image: url(../assets/images/banner.png);
  /* background-position-x: 180%;
  background-position-y: 32%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #acc1d2;
}
.bgBanner .content {
  background: transparent;
  /* transition: none; */
}

.leftBar {
  height: 100%;
  width: 60px;
  background: var(--secondaryColor);
  /* background: transparent; */
  transition: all 0.6s;
  filter: drop-shadow(0px 0px 8px #000);
  position: fixed;
  left: 0;
  z-index: 9999;
  overflow: hidden;
  overflow-y: auto;
}
.leftBar.active {
  width: 15%;
}
.leftBar>ul {
  padding-top: 0px !important;
}
.leftBar>ul>li {
  margin-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
}
.leftBar>ul>li:not(:first-child)>div {
  display: flex;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
}
.leftBar>ul>li:not(:first-child)>div {
  width: 100%;
}
.leftBar>ul>li.active>div {
  background: var(--primaryColor);
  width: 100%;
  border-radius: 3px;
  padding: 5px 8px;
}

.leftBar>ul>li>div {
  display: flex;
  justify-content: center;
}
.leftBar>ul>li svg {
  color: #ccc;
}
.leftBar>ul>li .menuText {
  opacity: 0;
  color: #fff;
  transition: all 0.5s;
}
.leftBar>ul>li .menuText span {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 2px;
  color: #ccc;
  width: 100%;
}
.leftBar>ul>li.active .menuText span {
  color: #fff;
  font-weight: 600;
}
.leftBar.active>ul>li .menuText {
  opacity: 1;
}
.leftBar>ul>li.active svg {
  color: #fff;
}
.leftBar>ul>li:first-child {
  border-bottom: 2px solid #b1b1b1;
  height: 55px;
  margin-bottom: 15px;
}

.uploadDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 3px;
}
.uploadDiv>svg {
  font-size: 55px;
  margin-top: 5px;
  padding: 0px 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 5px #c3c3c3;
  color: var(--secondaryColor);
}

a {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
.container {
  width: 80%;
  margin: auto;
}
label.formLabel {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
}
.textJustify{
  text-align: justify;
}
.textCenter{
  text-align: center;
}
.bold{
  font-weight: bold;
}
.yesIcon {
  color: #00bd00;
  font-size: 18px !important;
}
.noIcon {
  color: #ff0000;
  font-size: 18px;
}
.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter{
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
}
.formGroup{
    margin-bottom: 15px;
    position: relative;
}
.formGroup>label {
  font-size: 14px;
  font-weight: 500;
}
.uiChkBox svg {
  color: var(--primaryColor);
}
.pageInfo>h4 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primaryColor);
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
}
.pageInfo>p {
  font-size: 12px;
  color: var(--subTitleGray);
  margin-top: 5px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}
ul.breadcrumbs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
}
ul.breadcrumbs>li {
  cursor: pointer;
  margin-right: 5px;
}
ul.breadcrumbs>li.active {
  font-weight: 400;
  cursor: default;
  color: var(--subTitleGray);
}
.uiTxt.uploadFile input {
  padding-top: 4px;
  padding-left: 4px;
  height: 30px;
}


.gridOption {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.gridOption .uiTxt {
  width: 40%;
  margin-right: 10px;
}
.gridOption .iconBtn {
  padding: 5px;
  margin-right: 10px;
}
.gridOption .iconBtn svg {
  font-size: 18px;
}
.options button {
  padding: 8px;
}
.options button svg {
  font-size: 17px;
}

.uiAutoTxt {
  margin-top: 5px;
}

.activeColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.activeColumn svg {
  font-size: 18px;
}

.pageHeader {
  background: #fff !important;
  z-index: 9999;
}
.headerMenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 75%;
  justify-content: flex-end;
}
.headerMenu {
    color: #000 !important;
    font-size:12px !important;
    font-weight: 500 !important;
}
.logoContainer {
    width: 25%;
    cursor: pointer;
}
.logoContainer>img {
    height: 45px;
}
.copyright {
    background: #272727;
    color: #cdcdcd;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* TextBox */
.uiTxt{
  margin-top: 5px !important;
  width: 100%;
}
.uiTxt>div {
  height: 30px;
  background: #fff;
}
.uiTxt input{
  font-size: 12px;
  padding-top: 20px;
  padding-left: 10px;
  box-sizing: border-box;
}
.uiTxt>label {
  font-size: 12px;
  top: -8px;
  left: -5px;
}
.uiTxt>label[data-shrink="true"] {
    top: -2px;
}

/* Button */
.uiBtn{
    border:none !important;
    font-weight: 500 !important;
}


.uiLoader svg{
color:var(--primaryColor)
}

.uiAccordion {
  background: #f1f1f1 !important;
  box-shadow: 1px 2px 5px #d7d7d7 !important;
}
.uiAccordionContent {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background: #fff !important;
}
.uiAccordionTitle>div {
  margin: 0px !important;
}
.uiAccordionTitle>div h4 {
  margin: 0px !important;
}

.uiChkBox>span:last-child {
  font-size: 12px;
  position: relative;
  top: 1px;
}

.uiRadioGroup span>svg {
  font-size: 16px;
}
.uiRadioGroup label>span:last-child {
  font-size: 16px;
}
.uiRadioGroup label>span:last-child {
  font-size: 14px;
  position: relative;
  left: -2px;
  top: 1px;
}

.uiSwitchBtn>span:last-child {
  font-size: 14px;
  position: relative;
  top: 1px;
}

.uiTabs {
  min-height: 30px !important;
}
.uiTabs button {
    color: var(--primaryColor) !important;
    padding: 0px !important;
    min-height: 30px;
    font-size: 14px;
    letter-spacing: 2px;
}
.uiTabs>div>span {
  background: var(--primaryColor);
}

.uiPopOver{
  overflow: visible;
  z-index: 999999 !important;
}
.uiPopOver ul {
  padding: 0px;
}

.font12{
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}

.alignCenter{
  display: flex;
    justify-content: center;
}

.zoomBox{
  overflow: hidden;
}
img.zoomer {
  transition: transform 1s;
}
img.zoomer:hover {
  transform: scale(1.5);
}

.subTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--dimGray);
  padding-bottom: 10px;
}
.subTitle>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subTitle>div:first-child {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subTitle>div:last-child {
  width: 90%;
}
.subTitle>div:first-child>img {
  width: 50px;
  height:50px;
  background-color: var(--dimGray);
  border-radius: 50%;
}
.subTitle h4 {
  margin: 0px;
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.subTitle p {
  margin: 0px;
  margin-bottom: 4px;
  color: var(--subTitleGray);
}

.uiFooter {
  display: flex;
  flex-direction: column;
  background: #000;
}
.uiFooterMenus h4,.uiFooterMenus a {
  color: #fff;
}
ul.footerMenuList>li {
  color: #fff;
  font-weight: 500;
}
.uiFooterMenus .container {
  display: flex;
}
.uiFooterMenus .container>div {
  width: 33%;
}
.footerMenuList>li svg {
  font-size: 12px;
}

img.responsive,
video.responsive {
  width: 100%;
}

.swiper-pagination {
  position: relative !important;
}
.swiper-button-prev {
  top: 44% !important
}
.swiper-button-next {
  top: 46% !important;
}

.uiFooterMenus .container{
  justify-content: space-between;
}
.uiFooterMenus .container>div:first-child {
  width: 20%;
}
.uiFooterMenus .container>div:nth-child(2) {
  width: 40%;
}
.uiFooterMenus .container>div:nth-child(3) {
  width: 30%;
}
.uiFooter .blogs {
  width: 100%;
}
.uiFooter .blog {
    display: flex;
    flex-direction: row;
}
.uiFooter .blog>div:first-child {
  width: 40%;
}
.uiFooter .blog>div:last-child {
  width: 60%;
  padding-left: 10px;
}
.blog h4 {
  margin-top: 0px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 500;
}
.blog p {
  margin: 0px;
  font-size: 12px;
  color: #fff;
  text-align: justify;
}

.subscribe {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
}
.subscribeInput {
  display: flex;
  justify-content: space-between;
}
.subscribeInput {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.subscribeInput .uiTxt {
  background: #fff;
  border-radius: 4px;
}
.subscribeInput button{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 35px;
  margin-left: 15px;
}


.social {
  display: flex;
  justify-content: center;
}
.social svg {
  color: #fff;
  font-size: 24px;
}
.social ul {
  display: flex;
}
.social ul>li {
  padding: 4px;
  margin-right: 15px;
}

.uiAutoTxt>div>div {
  height: 30px;
}
.uiAutoTxt input{
  padding-top: 0px !important;
  margin-top: 0px;
  font-size: 13px;
}
.uiAutoTxt div {
  background: #fff;
  border-radius: 3px;
}
.uiAutoTxt label {
  font-size: 12px;
  color: #686868;
}
.uiTxt.multiLine>div {
  height: auto;
  font-size: 14px;
}
.uiTxt>div.Mui-focused{
box-shadow: 1px 1px 4px 2px #6da7f3 !important;
}
.uiAutoTxt>div>div.Mui-focused{
box-shadow: 1px 1px 4px 2px #6da7f3 !important;
}

.loadingBox{
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
}
.groupBox>.groupTitle {
  width: 100%;
}
.groupBox>.groupTitle>h4 {
  font-size: 16px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
  background: #e7e7e7;
  padding: 5px 10px;
}
.formRowWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.formRowWrapper .uiAutoTxt {
  margin-top: 6px;
}
.formRowWrapper>.formGroup {
  width: 25%;
}

.favBtn {
  position: absolute;
  z-index: 999;
  right: 0;
}
.favBtn svg {
  color: #fff;
}
.favBtn.active svg {
    color: var(--likeBtn);
}
.uiDialog {
  z-index: 9999 !important;
}

.rangeSlider>p {
  font-size: 14px;
  margin: 0px;
  position: relative;
  left: -2px;
}


.autocompletePopper {
  border: 1px solid #c6c6c6;
  border-top: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}
.autocompletePopper li{
  font-size: 12px !important;
}

.projectFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
}
.projectFiles>div {
  width: 150px;
  height: 110px;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 5px #d3d3d3;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

table#reportGrid.purpleBtm tbody tr:last-child {
    background: purple;
    color: #fff;
}

.historyBox {
  margin-bottom: 25px;
  border-left:3px solid #888;
  padding-left: 8px;
  cursor: pointer;
}
.historyBox h4 {
  margin-bottom: 0px;
  font-size: 16px;
  margin-top: 0px;
  color: #888;
  font-weight: 500;
}
.historyBox p {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #888;
  text-transform: capitalize;
}
.historyBox.active {
  border-color:var(--primaryColor);
}
.historyBox.active h4,.historyBox.active p {
  font-weight: bold;
  color: var(--primaryColor);
}

.fileBox {
  width: 100px;
  height: 90px;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 10px #f5f5f5;
  margin-right: 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fileBox>img {
  width: 100%;
  height: 100%;
}
.contentCard {
  margin-bottom: 5px;
}
.contentCard h4 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
}
.contentCard p {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
}
div#responseBox {
  margin-top: 15px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}
.filesList {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
div#responseList {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  padding-top: 15px;
}
div#responseList>div {
  border: 1px solid #ccc;
  padding: 10px 15px;
  border-radius: 4px;
  padding-bottom: 20px;
  margin-bottom: 15px;
  position:relative;
}
div#responseList>div>div::before {
  display: block;
  content: '';
  left: -20px;
  top: 10px;
  position: absolute;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid #d1d1d1;
  border-bottom: 10px solid transparent;
}
.responseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.responseHeader p{
  font-size: 14px;
  margin-top: 10px;
    margin-bottom: 10px;
}
.responseHeader>.userBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.responseHeader>.userBox>p {
  color: #000;
  margin-left: 5px;
  margin-top: 8px;
  text-transform: capitalize;
}
.responseText {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
}


.MuiDataGrid-root .MuiDataGrid-cell {
  color: #000;
  white-space: normal !important;
  word-wrap: break-word !important;
  padding: 5px 0px !important;
  padding-left: 5px !important;
}


.pageInfo {
  margin-bottom: 10px;
}
.graphs {
  display: flex;
  flex-direction: row;
  width: 98%;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.graphBox {
  display: flex;
}
.graphBox>div {
  border: 1px solid #ccc;
}

.pageCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.formRow {
  display: flex;
  justify-content: space-between;
}
.width100 {
  width: 100%;
}
.width70 {
  width: 70%;
}
.width50 {
  width: 50%;
}
.width48 {
  width: 48%;
}
.width45 {
  width: 45%;
}
.width33 {
  width: 33%;
}.width30 {
  width: 30%;
}
.width25 {
  width: 25%;
}
.width20 {
  width: 20%;
}

.modalTitle{
  color:var(--primaryColor)
}

.uploadFile.hide {
  display: none;
}

svg.smallIcon {
  font-size: 20px;
}

.ehsHeading1{
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.ehsHeading2{
  font-size: 14px;
  margin-bottom: -10px;
  margin-top: 0px;
  margin-left: 26px;
}

div#root>div:nth-child(2) {
  z-index: 99999;
}

.thumbnailBox {
  position: relative;
}

.thumbnailBoxIcon{
  position: absolute !important;
  top: 4px;
  right: 4px;
  background-color: #fff !important;
  padding: 4px !important;
}

.thumbnailBoxIcon svg {
  font-size: 18px;
  color: red;
}

.cursor {
  cursor: pointer;
}

.checkList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.checkList .uiChkBox{
  width: 30%;
}

.uiChkBox>span {
  font-size: 14px !important;
}

.flexRow{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.flexRowJustify {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.borderBottom {
  border-bottom: 3px solid #e8e8e8;
  padding-bottom: 5px;
}

table.htmlExcel {
  font-size: 1rem;
}
table.htmlExcel th{
}
table.htmlExcel h1,
table.htmlExcel h2,
table.htmlExcel h3,
table.htmlExcel h4 {
  font-size: 1rem;
  margin-bottom: 5px;
  margin-top: 5px;
}
table.htmlExcel  ol,
table.htmlExcel  ul{
  margin: 0px;
  padding-left: 25px;
}
table.htmlExcel>tbody>tr>td:nth-child(5) {
  width: 40%;
}
table.htmlExcel>tbody>tr>td:nth-child(4) {
  width: 20%;
}
table.htmlExcel input[type="radio"] {
  width: 50px;
}

.flexRowAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
